import React from 'react'
import { ApiDamageType } from '../../ApiTypes'
import ButtonList from '../../components/ButtonList'
import DefaultButton from '../../components/DefaultButton'
import ScreenHeading from '../../components/ScreenHeading'
import store from '../../redux/Store'
import { setDamageType } from '../../redux/SmartClaimSlice'
import Para from '../../components/Para'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import { StyleSheet, View } from 'react-native'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

interface SmartDamageTypeProps {
  navigation: any
  onChosen: Function
  goHome: Function
  goBack: Function
}

export default function SmartDamageType ({ navigation, onChosen, goHome, goBack }: SmartDamageTypeProps) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Please select the type of damage.
        </Para>
        <VerticalSpace height={15}/>
          <DefaultButton text="Scuff" onPress={doContinueScuff}/>
        <VerticalSpace height={15}/>
          <DefaultButton text="Chip" onPress={doContinueChip}/>
        <VerticalSpace height={15}/>
          <DefaultButton text="Dent" onPress={doContinueDent}/>
        <VerticalSpace height={15}/>
          <DefaultButton text="Scratch" onPress={doContinueScratch}/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
  
  function doContinueScuff()
  {
	onChosen('Scuff')
  }
  function doContinueChip()
  {
	onChosen('Chip')
  }
  function doContinueDent()
  {
	onChosen('Dent')
  }
  function doContinueDentWithBrokenPaint()
  {
	onChosen('Dent with Broken Paint')
  }
  function doContinueScratch()
  {
	onChosen('Scratch')
  }
}



const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
