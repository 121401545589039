import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

interface SmartSplashProps {
  navigation: any
  onSplashContinue: Function
  goHome: Function
  goBack: Function
}

export default function SmartSplash({ navigation, onSplashContinue, goHome, goBack }: SmartSplashProps) {
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          We’re sorry that your vehicle has been damaged.
        </Para>
        <Para>
          To help us arrange a repair we need you to provide:
        </Para>
			<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Details of the incident.</Text></View>
			<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Images of the damage.</Text></View>
			<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Confirmation the damage is within the limits defined in your terms and conditions. These were supplied when you purchased the product.</Text></View>
			<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Payment of the call out charge.</Text></View>
			<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Please have your payment details to hand.</Text></View>
        <Para>
          Then press continue.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onSplashContinue} text="Continue"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  listitemViews: {
	flexDirection: 'row'
  },
  listitems: {
	flex: 1,
	paddingLeft: 5,
	fontSize: 16
  },
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
