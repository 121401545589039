import React, { Component } from 'react';
import popupclosebutton from '../../icon-close2.png'
import { Image, StyleSheet, View, Dimensions, TouchableOpacity } from 'react-native'
import Dialog from "react-native-dialog";

class PhotoPreviewManage extends Component {
	
	constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      isOpen: false
    }
  }
	/*state = { isOpen: false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
    console.log("clicked");
  };*/
	  handleShowDialog = () => {
		console.log("clicked");
		this.setState({ isOpen: !this.state.isOpen });
	  };
  
  render() {
	  const dimensions = Dimensions.get('window');

  const imageWidth = (dimensions.width - 80);
    if (this.props.data) {
      return(
		<View style={{textAlign: 'center', height: 400, width: '100%'}}>
			<TouchableOpacity onPress={this.handleShowDialog} >
				<Image className="initialimage" source={{uri:this.props.data}} alt="preview" style={{width: imageWidth, height: '80%'}}/>
			</TouchableOpacity>
		{this.state.isOpen && (
		  <View style={{ position: 'relative'}}>
			<Dialog.Container visible={true} style={{ position: "fixed" }}>
			<TouchableOpacity onPress={this.handleShowDialog} style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <Image
              className="popupclosebutton"
              source={popupclosebutton}
              alt="preview"
			  style={{position: 'absolute',height: 70, width: 70, zIndex: 99, top: 20 }}
            />
			</TouchableOpacity>
			<TouchableOpacity onPress={this.handleShowDialog} >
            <Image
              className="popupimage"
              source={{uri:this.props.data}}
              alt="preview"
			  resizeMode={'contain'}
			  style={{width: dimensions.width, height: dimensions.height}}
            />
			</TouchableOpacity>
			</Dialog.Container>
		  </View>
        )}		  
        </View>
      );
    } else {
      return null;
    }
  }
}

export default PhotoPreviewManage;
