import React from 'react';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton';
import NavigationBar from '../../components/NavigationBar';
import Para from '../../components/Para';
import ScreenHeading from '../../components/ScreenHeading';
import Styles from '../../constants/Styles';
import VerticalSpace from '../../components/VerticalSpace'

interface SmartIntroProps {
  navigation: any
  onIntroContinue: Function
  goHome: Function
  goBack: Function
}

export default function SmartIntro ({ navigation, onIntroContinue, goHome, goBack }: SmartIntroProps) {
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Before submitting your repair request please check your terms and conditions to confirm the damage fits within the size (cms) and limits described. These were supplied when you purchased the product/s.
        </Para>
        <Para>
          Then press continue.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onIntroContinue} text="Continue"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
