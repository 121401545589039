import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Another from '../../components/Another'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import DefaultButton from '../../components/DefaultButton'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'

interface SmartAnotherProps {
  navigation: any
  onYes: Function
  onNo: Function
  goHome: Function
}

export default function SmartAnother ({ navigation, onYes, onNo, goHome }: SmartAnotherProps) {
  const emptyTestFunction = () => {}

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
        <ScreenHeading text="Repair Request"/>
        <View style={styles.container}>
          <Para>
            Thank you.
          </Para>
          <Para>
            Would you like to submit another repair request?
          </Para>
          <VerticalSpace height={15}/>
          <DefaultButton onPress={onYes} text="Yes"/>
          <VerticalSpace height={15}/>
          <DefaultButton onPress={onNo} text="No"/>
          <VerticalSpace height={15}/>
        </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
