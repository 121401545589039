import { AlloyClaim, ApiPolicyRequest, ApiUserSessionRequest, CancellationRequest, GapClaim, Photo, SmartClaim, TyreClaim, WarrantyClaim } from "../ApiTypes"
import * as ImageManipulator from 'expo-image-manipulator';
import each from 'async/each';
import {decode as atob, encode as btoa} from 'base-64'
import { Platform } from 'react-native'
import axios from 'axios';

export default class Api {
  accessToken: string
  apiBase: string
  password: string
  sessionToken: string
  standardTimeout: number
  urlBase: string
  username: string
  jobid: string
  ClientSecret: string

  constructor () {
    this.urlBase = 'https://api.autoprotect-app.co.uk/'
    this.apiBase = this.urlBase + 'api/'
    this.username = 'consumerapp'
    this.password = '13e77e4fb7c2346075fa1533420d1d2c'
    this.standardTimeout = 30 * 1000

    // OAuth token
    this.accessToken = ''
    // Customer session token
    this.sessionToken = ''
	this.jobid = ''
	this.booking_postcode = ''
	this.booking_address = ''
	this.repair_date = ''
	this.booking_key = ''
	this.submittedToShine = false
	
	this.ClientSecret = ''
	
    // Get the OAuth token on start-up
    this.getAccessToken()

    this.warrantyPhoto = this.warrantyPhoto.bind(this)
    this.alloyPhoto = this.alloyPhoto.bind(this)
    this.tyrePhoto = this.tyrePhoto.bind(this)
    this.smartPhoto = this.smartPhoto.bind(this)
	
  }

  xWwwFormUrlEncoded (data: any): string {
    const components = []
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      components.push(encodedKey + '=' + encodedValue)
    }
    return components.join('&')
  }

  async getAccessToken () {
    const api = this

    const data = this.xWwwFormUrlEncoded({
      grant_type: 'password',
      username: this.username,
      password: this.password
    })

    const response = await fetch(
      this.urlBase + 'token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: data
      }
    )
    const json = await response.json()
    api.accessToken = json.access_token
  }

  async stripePaymentIntent (done: Function, fail: Function) {

    const api = this
	
	if (this.submittedToShine == false)
	{
    const datesurl = this.apiBase +
      'shineshinedates/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&postcode=' + encodeURIComponent(this.booking_postcode) +
      '&address=' + encodeURIComponent(this.booking_address) +
      '&repair_date=' + encodeURIComponent(this.repair_date) +
      '&booking_key=' + encodeURIComponent(this.booking_key) + 
      '&type=shine'

    try {
      const response = await Promise.race([
        fetch(
          datesurl, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
	  console.log(json)
	  api.jobid = json.Job
	  api.submittedToShine = true
			const url = api.apiBase +
			  'shinepayment/?session_token=' + encodeURIComponent(api.sessionToken) +
			  '&jobid=' + encodeURIComponent(api.jobid)

			try {
			  const response2 = await Promise.race([
				fetch(
				  url, {
					method: 'GET',
					headers: api.accessHeaders()
				  }
				),
				api.timeoutPromise(api.standardTimeout)
			  ])
			  if (response2.status != 200) {
				  console.log('apifail')
				console.log(url)
				fail()
			  } else {
				  console.log(response2)
				const json = await response2.json()
				api.ClientSecret = json.ClientSecret
				done(json)
			  }
			} catch (e) {
				console.log(e)
			  fail(e)
			}
    } catch (e) {
				console.log(e)
      fail(e)
    }
	}
	else
	{
			const url = api.apiBase +
			  'shinepayment/?session_token=' + encodeURIComponent(api.sessionToken) +
			  '&jobid=' + encodeURIComponent(api.jobid)

			try {
			  const response2 = await Promise.race([
				fetch(
				  url, {
					method: 'GET',
					headers: api.accessHeaders()
				  }
				),
				api.timeoutPromise(api.standardTimeout)
			  ])
			  if (response2.status != 200) {
				  console.log('apifail')
				console.log(url)
				fail()
			  } else {
				  console.log(response2)
				const json = await response2.json()
				api.ClientSecret = json.ClientSecret
				done(json)
			  }
			} catch (e) {
				console.log(e)
			  fail(e)
			}
		
	}
	

  }
  
  async stripePayment (token: string, done: Function, fail: Function) {
	  
    const api = this
    const url = this.apiBase +
      'shinepayment/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&token=' + encodeURIComponent(token) + 
	  '&jobid=' + encodeURIComponent(this.jobid)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
        fail()
      } else {
        const json = await response.json()
        done(json)
      }
    } catch (e) {
      fail(e)
    }
	

  }

  async shinePaymentMade (claimId: string, paymentReference: string, done: Function, fail: Function) {
    const api = this
    const url = this.apiBase +
      'shineclaim?session_token=' + encodeURIComponent(this.sessionToken) +
      '&claimId=' + encodeURIComponent(claimId) +
      '&paymentReference=' + encodeURIComponent(paymentReference)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  console.log(response)
      if (response.status != 200) {
		  console.log('apifail')
        fail()
      } else {
        //const json = await response.json()
        done()
      }
    } catch (e) {
		  console.log(e)
      fail(e)
    }
  }

  async submitCallbackRequest (registrationNumber: string, postcode: string, surname: string, policyNumber: string, contactNumber: string, alternativeNumber: string, done: Function, fail: Function) {
    const api = this
    const url = this.apiBase +
      'callbackrequest?registrationNumber=' + encodeURIComponent(registrationNumber) +
      '&postcode=' + encodeURIComponent(postcode) +
      '&surname=' + encodeURIComponent(surname) +
      '&policyNumber=' + encodeURIComponent(policyNumber) +
      '&mobileNumber=' + encodeURIComponent(contactNumber) +
      '&alternativeNumber=' + encodeURIComponent(alternativeNumber)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
        fail()
      } else {
        const json = await response.json()
        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }

  timeoutPromise (timeout: number): Promise<Response> {
    return new Promise<Response>((resolve, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  }

  async policies (requestData: ApiPolicyRequest, done: Function, notFound: Function, fail: Function) {
    const api = this
    let url = this.apiBase +
      'policies/?registrationNumber=' + encodeURIComponent(requestData.registrationNumber) +
      '&postcode=' + encodeURIComponent(requestData.postcode) +
      '&surname=' + encodeURIComponent(requestData.surname) +
      '&policyNumber=' + encodeURIComponent(requestData.policyNumber) +
      '&version=6';

    // Fake a not found response.
    if (requestData.registrationNumber === 'bad' && requestData.policyNumber !== 'good') {
      url = this.apiBase + '404'
    }

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
	  console.log(response)
      if (response.status === 404) {
        notFound()
      } else {
        const json = await response.json()
        api.sessionToken = json.session_token
        done(json)
      }
    } catch (e) {
      fail(e)
    }
  }

  async userSession (requestData: ApiUserSessionRequest, done: Function, fail: Function) {
    const api = this
    const url = this.apiBase +
      'usersession?session_token=' + encodeURIComponent(this.sessionToken) +
      '&registrationNumber=' + encodeURIComponent(requestData.registrationNumber) +
      '&postcode=' + encodeURIComponent(requestData.postcode) +
      '&policyNumber=' + encodeURIComponent(requestData.policyNumber) +
      '&firstName=' + encodeURIComponent(requestData.firstName) +
      '&lastName=' + encodeURIComponent(requestData.surname) +
      '&mobileNumber=' + encodeURIComponent(requestData.mobileNumber) +
      '&email=' + encodeURIComponent(requestData.email) +
      '&mileage=' + encodeURIComponent(requestData.mileage) +
      '&src=' + Platform.OS;

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      api.sessionToken = json.session_token
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async refundAmount (cancellation: CancellationRequest, done: Function, fail: Function) {
    const url = this.apiBase +
      'refundamount/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(cancellation.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(cancellation.policy.id) +
      '&cancellationDate=' + this.formatDate(cancellation.date)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.amount)
    } catch (e) {
      fail(e)
    }
  }

  async postcodeLookup (postcode: string, done: Function, fail: Function) {
	  console.log('postcodeLookup');
    const url = this.apiBase +
      'postcodelookup/?postcode=' + encodeURIComponent(postcode)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.result)
    } catch (e) {
      fail(e)
    }
  }

  async shineDatesLookup (postcode: string, done: Function, fail: Function) {
    const url = this.apiBase +
      'shineshinedates/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&postcode=' + encodeURIComponent(postcode)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json.Results)
    } catch (e) {
      fail(e)
    }
  }

  async cancellation (cancellation: CancellationRequest, done: Function, fail: Function) {
    const url = this.apiBase +
      'cancellation/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(cancellation.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(cancellation.policy.id) +
      '&product=' + encodeURIComponent(cancellation.policy.product) +
      '&cancellationDate=' + this.formatDate(cancellation.date) +
      '&cancellationReason=' + encodeURIComponent(cancellation.reason) +
      '&cancellationFeePaid=' + this.formatDate(new Date())

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async cancellationPayment (cancellation: CancellationRequest, token: string, done: Function, fail: Function) {
    const url = this.apiBase +
      'worldpaypayment/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&cancellation_id=' + encodeURIComponent(cancellation.id) +
      '&wp_token=' + encodeURIComponent(token)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async cancellationBankDetails (cancellation: CancellationRequest, accountName: string, sortCode: string, accountNumber: string, done: Function, fail: Function) {
    const url = this.apiBase +
      'CancellationBankDetails/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&cancellation_id=' + encodeURIComponent(cancellation.id) +
      '&account_name=' + encodeURIComponent(accountName) +
      '&sort_code=' + encodeURIComponent(sortCode) +
      '&account_number=' + encodeURIComponent(accountNumber)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async gapClaim (claim: GapClaim, done: Function, fail: Function) {
    const url = this.apiBase +
      'gapclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(claim.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(claim.policy.id) +
      '&dateDeclaredLoss=' + this.formatDate(claim.insurerDate) +
      '&isVehicleFinanced=' + claim.financed +
      '&isSettlementFigureAccepted=' + claim.settlementAccepted +
      '&mileageAtIncident=' + encodeURIComponent(claim.mileage) +
      '&incidentDate=' + this.formatDate(claim.incidentDate) +
      '&incidentType=' + encodeURIComponent(claim.incident) +
      '&newForOldOffered=' + claim.newForOld

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async resendGapEmail (claim: GapClaim, done: Function, fail: Function) {
    const url = this.apiBase +
      'gapresendemail/?gapClaimId=' + encodeURIComponent(claim.id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async warrantyClaim (claim: WarrantyClaim, done: Function, fail: Function) {
    const url = this.apiBase +
      'warrantyclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(claim.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(claim.policy.id) +
      '&dateOfBreakdown=' + this.formatDate(claim.date) +
      '&faultCategory=' + encodeURIComponent(claim.faultCategory) +
      '&additionalInfo=' + encodeURIComponent(claim.additionalInfo) +
      '&vehicleLocation=' + encodeURIComponent(claim.vehicleLocation)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async warrantyPhoto (claimId: number, photo: Photo, done: Function, fail: Function) {
    await this.uploadPhoto('warrantyClaimPhoto/', 'warrantyClaimId', claimId, photo, done, fail)
  }

  async alloyClaim (claim: AlloyClaim, done: Function, fail: Function) {
    const url = this.apiBase +
      'alloyclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(claim.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(claim.policy.id) +
      '&dateOfDamage=' + this.formatDate(claim.date) +
      '&descriptionOfDamage=' + encodeURIComponent(claim.description) +
      '&typeOfDamage=' + encodeURIComponent(claim.damageType) +
      '&typeOfWheel=' + encodeURIComponent(claim.wheelType) +
      '&whichWheel=' + encodeURIComponent(claim.chosenWheel)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async alloyPhoto (claimId: number, photo: Photo, done: Function, fail: Function) {
    await this.uploadPhoto('alloyClaimPhoto/', 'alloyClaimId', claimId, photo, done, fail)
  }

  async tyreClaim (claim: TyreClaim, done: Function, fail: Function) {
    const url = this.apiBase +
      'tyreclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(claim.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(claim.policy.id) +
      '&dateOfDamage=' + this.formatDate(claim.date) +
      '&typeOfDamage=' + encodeURIComponent(claim.damageType) +
      '&whichWheel=' + encodeURIComponent(claim.chosenWheel) +
      '&isDrivable=' + encodeURIComponent(claim.isDrivable)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }

  async tyrePhoto (claimId: number, photo: Photo, done: Function, fail: Function) {
    await this.uploadPhoto('tyreclaimphoto/', 'tyreClaimId', claimId, photo, done, fail)
  }

  async smartClaim2 (claim: SmartClaim, done: Function, fail: Function) {
    const url = this.apiBase +
      'smartclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(claim.policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(claim.policy.id) +
      '&dateOfDamage=' + this.formatDate(claim.date) +
      '&typeOfDamage=' + encodeURIComponent(claim.damageType) +
      '&locationOfDamage=' + encodeURIComponent(claim.damageLocation)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
  }
  
   async smartClaim(policy, date, damageType, damageLocation, done, fail) {
    let url = this.apiBase +
      'shineclaim/?session_token=' + encodeURIComponent(this.sessionToken) +
      '&policyNumber=' + encodeURIComponent(policy.policyNo) +
      '&policySectionId=' + encodeURIComponent(policy.id) +
      '&dateOfDamage=' + this.formatDate(date) +
      '&typeOfDamage=' + encodeURIComponent(damageType) +
      '&locationOfDamage=' + encodeURIComponent(damageLocation);

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
      done(json)
    } catch (e) {
      fail(e)
    }
   }


  async smartRepairDate (postcode: string, address: string, repairDate: string, bookingKey: string, done: Function, fail: Function) {
	  let my = this;
	  
	  
	this.booking_postcode = postcode
	this.booking_address = address
	this.repair_date = repairDate
	this.booking_key = bookingKey

  }

  /*async smartPhoto (claimId: number, photo: Photo, done: Function, fail: Function) {
    await this.uploadPhoto('smartclaimphoto/', 'smartClaimId', claimId, photo, done, fail)
  }*/
  
  smartPhoto(claimId, imageData, photoType, done, fail, progress) {
    this.uploadPhoto('shineclaimphoto/', 'smartClaimId', claimId, imageData, photoType, done, fail, progress);
  }


  /*async uploadPhoto (endpoint: string, claimIdParam: string, claimId: number, photo: Photo, done: Function, fail: Function) {
    const url = this.apiBase + endpoint +
      '?session_token=' + encodeURIComponent(this.sessionToken) +
      '&' + claimIdParam + '=' + claimId +
      '&photoType=' + encodeURIComponent(photo.type)

    const blob = this.dataURItoBlob(photo.base64)
    const fd = new FormData()
    fd.append('uploadedImage', blob, 'photo.png')

    $.ajax({
      url: url,
      headers: this.accessHeaders(),
      contentType: false,
      enctype: 'multipart/form-data',
      data: fd,
      processData: false,
      method: 'POST',
      timeout: 5 * 60 * 1000
    }).done(function(data) {
      done(data)
    }).fail(function(xhr) {
      fail(xhr)
    })
  };*/
  
  /*async uploadPhoto(endpoint, claimIdParam, claimId, imageData, photoType, done, fail) {
    let url = this.apiBase + endpoint +
      '?session_token=' + encodeURIComponent(this.sessionToken) +
      '&' + claimIdParam + '=' + claimId +
      '&photoType=' + encodeURIComponent(photoType);
	  
	  console.log(url);
	  
	try {
	const manipResult = await ImageManipulator.manipulateAsync(
      imageData,
	  [{ resize: { width: 640 } }],
      { compress: 0.1, format: ImageManipulator.SaveFormat.PNG, base64: true }
    );
    let blob = '';
	
    let fd = new FormData();

	if (Platform.OS == "android")
	{
		blob = this.dataURItoBlob('data:image/png;base64,' + manipResult.base64);
		fd.append('file', {
			uri: manipResult.uri,
			type: 'image/png',
			name: 'photo.png',
			data: blob,
		  });
	}
	else
	{
		blob = this.dataURItoBlob(manipResult.uri);
		fd.append('uploadedImage', blob, 'photo.png');
	}
	
	//console.log(blob);
	
	  
	  
	
      const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders(),
			body: fd,
			timeout: 5 * 60 * 1000,
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      const json = await response.json()
		console.log(json)
      done(json)
    } catch (e) {
	  //console.log(e);
      fail(e)
    }

  };*/

  async uploadPhoto(endpoint, claimIdParam, claimId, imageData, photoType, done, fail, progress) {
    let url = this.apiBase + endpoint +
      '?session_token=' + encodeURIComponent(this.sessionToken) +
      '&' + claimIdParam + '=' + claimId +
      '&photoType=' + encodeURIComponent(photoType);
	  
	try {
	const manipResult = await ImageManipulator.manipulateAsync(
      imageData,
	  [{ resize: { width: 640 } }],
      { compress: 0.1, format: ImageManipulator.SaveFormat.PNG, base64: true }
    );
    let blob = '';
	
    let fd = new FormData();

	if (Platform.OS != "web")
	{
		blob = this.dataURItoBlob('data:image/png;base64,' + manipResult.base64);
		//fd.append('uploadedImage', blob, 'photo.png');
		fd.append('uploadedImage', {
			uri: manipResult.uri,
			type: 'image/png',
			name: 'photo.png',
			data: blob,
		  });
	}
	else
	{
		blob = this.dataURItoBlob(manipResult.uri);
		fd.append('uploadedImage', blob, 'photo.png');
	}	

      /*const response = await Promise.race([
        fetch(
          url, {
            method: 'POST',
            headers: this.accessHeaders(),
			body: fd,
			timeout: 5 * 60 * 1000,
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])*/
	  

	//const axios = require('axios').default;

	axios.request({
		 method: "post", 
		url: url, 
		data: fd,
		headers: this.accessHeadersFormData(),
		timeout: 5 * 60 * 1000,
		onUploadProgress: (p) => {
		  progress(photoType, p.loaded, p.total)
		}
	})
	  .then(function (response) {
		  done(response)
	  })
	  .catch(function (error) {
		// handle error
		console.log(error)
		fail(error)
	  })
	  .finally(function () {
		// always executed
	  });

    } catch (e) {
		console.log(e)
      fail(e)
    }

  };

  accessHeadersFormData() {
	  let dtnow = new Date()
	  if (this.accessTokenExpiryTime < dtnow)
	  {
		  this.getAccessToken()
	  }

    return {
      "Authorization": "Bearer " + this.accessToken,
	  "Content-Type": "multipart/form-data"
    };
  }

  // Uploads photos in parallel. Each photo object in the array should have the
  // following structure:
  // { data: 'uri-image-data', type: 'photo type' }
  // photoFunc should be one of the api photo uploading functions (alloyPhoto,
  // tyrePhoto, or smartPhoto).
  /*async uploadPhotos (photoFunc: Function, claimId: number, photos: [Photo], done: Function, fail: Function) {
    // TODO: Finish rewriting Promise.all!
    Promise.all([
      photos,
      function submit(photo, callback) {
        photoFunc(
          claimId,
          photo,
          function done() {
            callback()
          },
          function fail(xhr) {
            callback(xhr)
          }
        )
      },
      function callback(err) {
        if (err) {
          fail(err)
        } else {
          done()
        }
      }
    ])
  }*/
  
  uploadPhotos(photoFunc, claimId, photos, done, fail, uploadProgress) {
    each(
      photos,
      function submit(photo, callback) {
        photoFunc(
          claimId,
          photo.data,
          photo.type,
          function done() {
            callback();
          },
          function fail(xhr) {
            callback(xhr);
          },
		  uploadProgress
        )
      },
      function callback(err) {
        if (err) {
          fail(err);
        } else {
          done();
        }
      }
    );
  }

  accessHeaders () {
    return {
      Authorization: 'Bearer ' + this.accessToken
    }
  }

  formatDate (date: Date | string): string {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    return [year, month, day].join('-')
  }

  // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }
}
