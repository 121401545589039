import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface YourVehicleProps {
  policy: any
  policies: any
  navigation: any
  onNotYourVehicle: Function
  onDone: Function
  goHome: Function
  goBack: Function
}

export default function YourVehicle ({ policy, policies, navigation, onNotYourVehicle, onDone, goHome, goBack }: YourVehicleProps) {
  const [carModelState, setCarModelState] = useState(policies.vehicle.model)

  const replaceAllButLastThree = (s : string) => {
    const { length } = s
    if (length > 4) {
      const substringToBeReplaced = s.substring(0, length - 3)
      return s.replace(substringToBeReplaced, '****')
    }
  }

  const truncateAfter15 = (s : string) => {
	  console.log(s)
	  let val = s;
    if (s.length > 15) {
      val = s.substring(0, 15) + '...'
    }
	return val
  }

  const [carRegistrationState, setRegistrationState] = useState(replaceAllButLastThree(policies.vehicle.VRN))
  const [dealerNameState, setDealerNameState] = useState(policies.dealer_name)
  
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Vehicle Confirmation" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          From the information you have provided, please confirm this information is correct.
        </Para>
        <VerticalSpace height={15}/>
        <Para>
          Make: {truncateAfter15(carModelState)}
        </Para>
        <Para>
          Registration: {carRegistrationState}
        </Para>
        <Para>
          Dealer: {dealerNameState}
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onDone} text="Yes"/>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onNotYourVehicle} text="No"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
