import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Heading from '../../components/Heading'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface SmartPaymentFail2Props {
  navigation: any
  onReturn: Function
  goHome: Function
}

export default function SmartPaymentFail2 ({ navigation, onReturn, goHome }: SmartPaymentFail2Props) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request"/>
      <View style={styles.container}>
        <Heading>Error</Heading>
        <Para>
        We're Sorry. We have been unable to collect your call out fee at this time. Your repair request has been received and a confirmation of your appointment will be sent shortly. You will be contacted again by Shine! and they will reattempt to take the call out fee. You DO NOT need to contact us; our representative will contact you within two working days.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onReturn} text="Return to main screen"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
