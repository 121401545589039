import React, { Component, useState } from 'react';
import { Keyboard, Pressable, StyleSheet, Text, View, Image } from 'react-native'
import Bottom from '../../components/Bottom';
import DefaultButton from '../../components/DefaultButton';
import ScreenHeading from '../../components/ScreenHeading';
import ButtonList from '../../components/ButtonList';
import waitingimg from '../../loading-png-gif.gif';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

interface SmartVehicleLocationStatementProps {
  navigation: any
  onLocationNo: Function
  onLocationContinue: Function
  goHome: Function
  goBack: Function
}

export default function SmartVehicleLocationStatement({ navigation, onLocationNo, onLocationContinue, goHome, goBack }: SmartVehicleLocationStatementProps) {
	const [loading, setLoading] = useState(true)
	
    setTimeout(function () {
		
		setLoading(false);


    }, 50);
  
  
		if (loading == true)
		{
			return (
				<KeyboardAwareScrollView style={styles.outerContainer}>
				  <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
				  <ScreenHeading text="Repair Request" goBack={goBack}/>
				  <View style={styles.container}>
					<Para>
					  Please wait... 
					</Para>
					<VerticalSpace height={15}/>
					<View style={styles.imageWrapper} >
						<Image style={styles.rowImage} alt="Waiting..." source={waitingimg}></Image>
					</View>
				  </View>
				</KeyboardAwareScrollView>
			);
		}
		else
		{
			  return (
				<KeyboardAwareScrollView style={styles.outerContainer}>
				  <NavigationBar navigation={navigation}></NavigationBar>
				  <ScreenHeading text="Book Repair" goBack={goBack}/>
				  <View style={styles.container}>
					<Para>
					  Thank you. 
					</Para>
					<Para>
					  Your repair request has been approved subject to the information you have provided.
					</Para>
					<Para>
					  In order for us to safely carry out your repair we will require:
					</Para>
						<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Payment of your call out fee as per your terms and conditions please have your payment card available.</Text></View>
						<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Home or work address.</Text></View>
						<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>A safe location i.e. driveway or private off-street parking.</Text></View>
						<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Space for your vehicle and the technician's medium-sized van.</Text></View>
						<View style={styles.listitemViews}><Text>{'\u2022'}</Text><Text style={styles.listitems}>Supply parking permits where required.</Text></View>
					<Para>
						Does your location meet the requirements above?
					</Para>
					<VerticalSpace height={15}/>
					<DefaultButton text="Yes" onPress={onLocationContinue} />
					<VerticalSpace height={15}/>
					<DefaultButton text="No" onPress={onLocationNo} />
					<VerticalSpace height={15}/>
				  </View>
				</KeyboardAwareScrollView>

			);
		}
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  listitemViews: {
	flexDirection: 'row'
  },
  listitems: {
	flex: 1,
	paddingLeft: 5,
	fontSize: 16
  },
  rowImage: {
	  height: 200,
    width: 200
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  }
})

