import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface PolicyHolderProps {
  navigation: any
  onPolicyHolder:Function
  onNotPolicyHolder:Function
  goHome: Function
  goBack: Function
}

export default function PolicyHolder ({ navigation, onPolicyHolder, onNotPolicyHolder, goHome, goBack }: PolicyHolderProps) {
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Welcome" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Please let us know if you’re the agreement holder.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onPolicyHolder} text="Yes"/>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onNotPolicyHolder} text="No"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
