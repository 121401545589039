import React, { FunctionComponent } from 'react'
import { Image, Linking, StyleSheet, TouchableOpacity, View } from 'react-native'
import Styles from '../constants/Styles'

type NavigationBarIconsProps = {
  navigation: any
  goHome: Function
}

const NavigationBarIcons: FunctionComponent<NavigationBarIconsProps> = ({ navigation, goHome }) => {
  if (true) {
    return shineNav({ navigation, goHome })
  } else {
    return autoprotectNav({ navigation })
  }
}

const shineNav: FunctionComponent<NavigationBarIconsProps> = ({ navigation, goHome }) => {
  const faqLink = 'https://shinerepair.com/faqs/'
  return (
    <View style={styles.navigationBarIcons}>
      <TouchableOpacity onPress={goHome}>
        <Image style={styles.navigationBarIconSmall} source={require('../assets/home-dark.png')}></Image>
      </TouchableOpacity>
      <Image style={styles.navigationBarLogo} source={require('../assets/ShineProtectLogo.png')}></Image>
      <TouchableOpacity onPress={() => Linking.openURL(`${faqLink}`)}>
        <Image style={styles.navigationBarIconSmall} source={require('../assets/faq-dark.png')}></Image>
      </TouchableOpacity>
    </View>
  )
}

const autoprotectNav: FunctionComponent<NavigationBarIconsProps> = ({ navigation, goHome }) => {
  const faqLink = 'https://www.autoprotect.co.uk/customer_support'
  return (
    <View style={styles.navigationBarIcons}>
      <TouchableOpacity onPress={goHome}>
        <Image style={styles.navigationBarIconSmall} source={require('../assets/home.png')}></Image>
      </TouchableOpacity>
      <Image style={styles.navigationBarLogo} source={require('../assets/logo.png')}></Image>
      <TouchableOpacity onPress={() => Linking.openURL(`${faqLink}`)}>
        <Image style={styles.navigationBarIconSmall} source={require('../assets/faq.png')}></Image>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBarIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Styles.standardPadding
  },
  navigationBarLogo: {
    height: 47,
    width: 90,
	marginTop: 3
  },
  navigationBarIconSmall: {
	marginTop: 10,
    height: 30,
    width: 38
  }
})

export default NavigationBarIcons
