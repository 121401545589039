import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AsyncStorage from '@react-native-async-storage/async-storage'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import ScreenHeading from '../../components/ScreenHeading'
import LabeledTextField from '../../components/LabeledTextField'
import store, { RootState } from '../../redux/Store'
import { setPolicyNumber } from '../../redux/DetailsSlice'
import ConditionalButton from '../../components/ConditionalButton'
import DefaultButton from '../../components/DefaultButton'

interface PolicySignInProps {
  navigation: any
  onContinue:Function
  onNoAgreementNumber: Function
  goHome: Function
}

export default function PolicySignIn ({ navigation, onContinue, onNoAgreementNumber, goHome }: PolicySignInProps) {
  const [buttonText, setButtonText] = useState('Continue')
  const details = useSelector((state: RootState) => state.details.value)

  function canContinue () {
    return details.policyNumber !== '' && buttonText === 'Continue'
  }
  
  function doContinue() {
    setButtonText('Please Wait...');
    onContinue(details.policyNumber, () => onFail());
  }
  
  function onFail() {
    setButtonText('Continue');
  }

  async function updatePolicyNumber (text: string) {
    store.dispatch(setPolicyNumber(text))
    await AsyncStorage.setItem('details.policyNumber', text)
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Agreement Search"/>
      <VerticalSpace height={15} />
      <View style={styles.container}>
        <Para>Hmmm; we don't seem to have a record on file against the details you've given, but don't worry. Please enter your agreement number (just the numbers – no letters) in the box below and tap 'Continue'. If you don't have that number, please tap 'No Agreement Number'.</Para>
        <LabeledTextField placeholder="Required" label="Policy number" value={details.policyNumber} onChange={updatePolicyNumber}/>
        <VerticalSpace height={15} />
        <ConditionalButton onPress={doContinue} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15} />
        <DefaultButton onPress={onNoAgreementNumber} text="No agreement number" />
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
