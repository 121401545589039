import React from 'react'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ApiDamageLocation } from '../../ApiTypes'
import ConditionalButton from '../../components/ConditionalButton'
import NavigationBar from '../../components/NavigationBar'
import ScreenHeading from '../../components/ScreenHeading'
import SmartDamageMap from './SmartDamageMap'
import store, { RootState } from '../../redux/Store'
import { setDamageLocation } from '../../redux/SmartClaimSlice'
import Para from '../../components/Para'
import { StyleSheet, View, Text } from 'react-native'
import Styles from '../../constants/Styles'
import { useSelector } from 'react-redux'
import VerticalSpace from '../../components/VerticalSpace'


interface SmartDamageLocationProps {
  navigation: any
  onDamageChosen: Function
  goHome: Function
  goBack: Function
}

 var state = {
      location: ''
};


const SmartDamageLocation = ({ navigation, onDamageChosen, goHome, goBack }: SmartDamageLocationProps) => {
  const location = useSelector((state: RootState) => state.smartClaim.value.damageLocation)

  const handleLocationChange = (chosenLocation: ApiDamageLocation) => {
    store.dispatch(setDamageLocation(chosenLocation))
	console.log(chosenLocation);
	state.location = chosenLocation
  }

  const canContinue = () => location !== null
  
  function doContinue()
  {
	  onDamageChosen(state.location)
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <Text style={styles.paraCcontainer}>
          Please tap to indicate the location of the damage.
        </Text><Text style={styles.paraCcontainerBold}>Note that damage to alloy wheels are not included.</Text>
        <VerticalSpace height={15}/>
        <SmartDamageMap location={location} onLocationChange={(location: ApiDamageLocation) => handleLocationChange(location)}/>
        <VerticalSpace height={15}/>
          <ConditionalButton text="Continue" condition={canContinue} onPress={doContinue}/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

export default SmartDamageLocation

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  imageContainer: {
	display: 'flex',
	margin: '0 auto'
  },
  paraCcontainer: {
	  margin: 'auto'
  },
  paraCcontainerBold: {
	  margin: 'auto',
	  fontWeight: 'bold'
  }
})
