import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import Heading from '../../components/Heading'
import NavigationBar from '../../components/NavigationBar'
import Styles from '../../constants/Styles'
import StandardSignIn from '../../screens/SignIn/StandardSignIn'
import FailedSignIn from '../../screens/SignIn/FailedSignIn';
import PolicySignIn from '../../screens/SignIn/PolicySignIn.tsx';
import PolicyHolder from '../../screens/SignIn/PolicyHolder';
import NotPolicyHolder from '../../screens/SignIn/NotPolicyHolder';
import YourDetails from '../../screens/SignIn/YourDetails';
import YourVehicle from '../../screens/SignIn/YourVehicle';
import RequestCallback from '../../screens/SignIn/RequestCallback';
import RequestCallbackThanks from '../../screens/SignIn/RequestCallbackThanks';
import ProductInfo from '../../ProductInfo'

class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      signInType: 'standard',
      registrationNumber: '',
      postcode: '',
      surname: '',
      policyNumber: '',
	  policy: null
    }

    this.handlePolicySignIn = this.handlePolicySignIn.bind(this);
    this.handleStandardSignIn = this.handleStandardSignIn.bind(this);
    this.handleNotPolicyHolder = this.handleNotPolicyHolder.bind(this);
    this.handlePolicyHolder = this.handlePolicyHolder.bind(this);
    this.handleSubmitDetails = this.handleSubmitDetails.bind(this);
    this.handleTryAgain = this.handleTryAgain.bind(this);
    this.handleYourVehicle = this.handleYourVehicle.bind(this);
    this.handleYourVehicle = this.handleYourVehicle.bind(this);
	this.handleRequestCallback = this.handleRequestCallback.bind(this);
	this.handleSubmitRequestCallback = this.handleSubmitRequestCallback.bind(this);
	this.handleGoHome = this.handleGoHome.bind(this);
	this.onFail = this.onFail(this);
  };

  componentDidMount() {
	  if (this.props.policies != null) {
		  if (this.props.policies.policies != null) {
			  
			const productInfo = new ProductInfo(this.props.policies);
			this.setState({policies: this.props.policies, policy: productInfo.policyIncluding("shineprotect")});
		  }
	  }
	  if (this.props.backToEnd == true)
	  {
		  this.setState({signInType: 'yourDetails'});
	  }
  }

  handlePolicySignIn(policyNumber, onFail) {
    const nextState = Object.assign(
      {}, this.state, {policyNumber: policyNumber}
    );
    this.setState(nextState);

    this.handleSignIn(nextState, 'failed', onFail);
  }

  handleStandardSignIn(registrationNumber, postcode, surname, onFail) {
    const nextState = Object.assign(
      {}, this.state, {registrationNumber: registrationNumber, postcode: postcode, surname: surname}
    );

    this.setState(nextState);

    this.handleSignIn(nextState, 'policy', onFail);
  }

  handleSignIn(state, notFoundScreen, onFail) {
    let signIn = this;

    this.props.api.policies(state, function done(policies) {
      if (policies) {
		const productInfo = new ProductInfo(policies);
		const policy = productInfo.policyIncluding("shineprotect");
		if (policy) {
			const nextState = Object.assign({}, state, {signInType: 'policyHolder', policies: policies, policy: policy});
			signIn.setState(nextState);
		}
		else
		{
			const nextState = Object.assign({}, state, {signInType: notFoundScreen});
			signIn.setState(nextState);
		}
      }
    }, function notFound() {
      const nextState = Object.assign({}, state, {signInType: notFoundScreen});
      signIn.setState(nextState);
    }, function fail(xhr) {
      console.log(xhr);
      onFail();
    });
  }
  
  onFail() {
    const nextState = Object.assign({}, this.state, {signInType: 'failed'});
    this.setState(nextState);
  }

  handleYourVehicle() {
    const nextState = Object.assign({}, this.state, {signInType: 'yourDetails'});
    this.setState(nextState);
  }

  handlePolicyHolder() {
    const nextState = Object.assign({}, this.state, {signInType: 'yourVehicle'});
    this.setState(nextState);
  }

  handleNotPolicyHolder() {
    const nextState = Object.assign({}, this.state, {signInType: 'notPolicyHolder'});
    this.setState(nextState);
  }

  handleSubmitDetails(firstName, surname, mobileNumber, email, mileage, onFail) {
    let signIn = this;
    const nextState = Object.assign(
      {}, this.state, {
        firstName: firstName,
        surname: surname,
        mobileNumber: mobileNumber,
        email: email,
        mileage: mileage
      }
    );
    this.setState(nextState);

    this.props.api.userSession(nextState, function done(data) {
      const details = {
        firstName: nextState.firstName,
        surname: nextState.surname,
        mobileNumber: nextState.mobileNumber,
        email: nextState.email,
        mileage: nextState.mileage
      };

      signIn.props.onAuthenticated(details, nextState.policies);
    }, function fail(xhr) {
      console.log(xhr);
      signin.onFail();
    });

  }

  handleTryAgain() {
    const nextState = Object.assign({}, this.state, {signInType: 'standard'});
    this.setState(nextState);
  }
  
  handleRequestCallback() {
    const nextState = Object.assign({}, this.state, {signInType: 'callback'});
    this.setState(nextState);
  }

  handleSubmitRequestCallback(mobileNumber, alternativeNumber) {
	  const signin = this;
    this.props.api.submitCallbackRequest(this.state.registrationNumber, this.state.postcode, this.state.surname, this.state.policyNumber, mobileNumber, alternativeNumber, function done(data) {
    }, function fail(xhr) {
      console.log(xhr);
      //signin.onFail();
    });
    const nextState = Object.assign({}, this.state, {signInType: 'callbackthanks'});
    this.setState(nextState);
  }
  
  handleGoHome() {
	console.log('gohome');
    const nextState = Object.assign({}, this.state, {signInType: 'standard'});
    this.setState(nextState);
  }

  render() {
	  
    switch(this.state.signInType) {
      case 'failed':
        return (
          <FailedSignIn onRequestCallback={this.handleRequestCallback} onTryAgain={this.handleTryAgain} goHome={() => this.handleGoHome()}/>
        );
      case 'callback':
        return (
          <RequestCallback onSubmit={this.handleSubmitRequestCallback} onReturn={this.handleTryAgain} goHome={() => this.handleGoHome()}/>
        );
      case 'callbackthanks':
        return (
          <RequestCallbackThanks onReturn={this.handleTryAgain} goHome={() => this.handleGoHome()}/>
        );
      case 'policy':
        return (
          <PolicySignIn onContinue={this.handlePolicySignIn} onNoAgreementNumber={() => this.setState({signInType: 'failed'})} policyNumber={this.state.policyNumber} goHome={() => this.handleGoHome()}/>
        );
      case 'policyHolder':
        return (
          <PolicyHolder onPolicyHolder={this.handlePolicyHolder} onNotPolicyHolder={this.handleNotPolicyHolder} goBack={() => this.setState({signInType: 'signin'})} goHome={() => this.handleGoHome()}/>
        );
      case 'notPolicyHolder':
        return (
          <NotPolicyHolder onDone={this.handleTryAgain} goHome={() => this.handleGoHome()}/>
        );
      case 'yourVehicle':
	  console.log(this.state)
        return (
          <YourVehicle policy={this.state.policy} goBack={() => this.setState({signInType: 'policyHolder'})} onDone={this.handleYourVehicle} onNotYourVehicle={() => this.setState({signInType: 'failed'})} policies={this.state.policies} goHome={() => this.handleGoHome()}/>
        );
      case 'yourDetails':
        return (
          <YourDetails onSubmit={this.handleSubmitDetails} surname={this.state.surname} goBack={() => this.setState({backToEnd: false, signInType: 'yourVehicle'})} goHome={() => this.handleGoHome()}/>
        );
      default:
        return (
          <StandardSignIn onContinue={this.handleStandardSignIn} registrationNumber={this.state.registrationNumber} postcode={this.state.postcode} surname={this.state.surname} goHome={() => this.handleGoHome()}/>
        );
    }
  }
}

export default SignIn;
