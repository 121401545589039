import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AsyncStorage from '@react-native-async-storage/async-storage'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import ScreenHeading from '../../components/ScreenHeading'
import LabeledTextField from '../../components/LabeledTextField'
import store, { RootState } from '../../redux/Store'
import { setPostcode, setRegistrationNumber, setSurname } from '../../redux/DetailsSlice'
import ConditionalButton from '../../components/ConditionalButton'

interface StandardSignInProps {
  navigation: any
  onContinue:Function
  goHome: Function
}

export default function StandardSignIn ({ navigation, onContinue, goHome }: StandardSignInProps) {
  const [buttonText, setButtonText] = useState('Continue')
  const details = useSelector((state: RootState) => state.details.value)

  function canContinue () {
    return details.registrationNumber !== '' && details.surname !== '' && buttonText === 'Continue'
  }
  
  function doContinue() {
    setButtonText('Please Wait...');
    onContinue(details.registrationNumber, details.postcode, details.surname, () => onFail());
  }
  
  function onFail() {
    setButtonText('Continue');
  }

  async function updateSurname (text: string) {
    store.dispatch(setSurname(text))
    await AsyncStorage.setItem('details.surname', text)
	console.log(text)
  }

  async function updateRegistrationNumber (text: string) {
    store.dispatch(setRegistrationNumber(text))
    await AsyncStorage.setItem('details.registrationNumber', text)
  }

  async function updatePostcode (text: string) {
    store.dispatch(setPostcode(text))
    await AsyncStorage.setItem('details.postcode', text)
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Agreement Search"/>
      <VerticalSpace height={15} />
      <View style={styles.container}>
		<Para>Welcome to the Shine! PROTECT app. To get things started we need to find your agreement..</Para>
        <Para>Please provide the information below.</Para>
        <LabeledTextField placeholder="Required" label="Registration number" value={details.registrationNumber} onChange={updateRegistrationNumber}/>
        <LabeledTextField placeholder="Required" label="Postcode" value={details.postcode} onChange={updatePostcode}/>
        <LabeledTextField placeholder="Required" label="Surname" value={details.surname} onChange={updateSurname}/>
        <VerticalSpace height={15} />
        <ConditionalButton onPress={doContinue} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
