import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'

interface NotPolicyHolderProps {
  navigation: any
  onDone: Function
  goHome: Function
}

export default function NotPolicyHolder ({ navigation, onDone, goHome }: NotPolicyHolderProps) {
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Sorry"/>
      <View style={styles.container}>
        <Para>
          We’re really sorry but under data protection regulation, as you’re not the agreement holder we’re unable to process your request. Please obtain approval from the agreement holder to process the repair request.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onDone} text="Return to Main Menu"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
