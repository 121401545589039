import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import Styles from '../constants/Styles'
import store from '../redux/Store'
import { setLoss } from '../redux/LossSlice'
import { setPolicy } from '../redux/PolicySlice'

interface WelcomeProps {
  navigation: any
  onContinue: Function
  goHome: Function
}

export default function Welcome ({ navigation, onContinue, goHome }: WelcomeProps) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <View style={styles.container}>
        <Heading>Welcome</Heading>
        <VerticalSpace height={20} />
		<Para>Thank you for visiting the Shine! app.</Para>
	    <Text style={styles.bullet}>&#8226; It’s easy to use and quick to make a claim.</Text>
	    <Text style={styles.bullet}>&#8226; You also have the ability to cancel a valid agreement.</Text>
	    <Text style={styles.bullet}>&#8226; Whatever you need to do, it will not take more than a few minutes to complete.</Text>
        <VerticalSpace height={40} />
        <DefaultButton onPress={() => onContinue()} text="Continue"/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  bullet: {
	  marginLeft: 10,
	  padding: 5,
	  fontSize: 16,
  },})
