import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface SmartNoAppointmentProps {
  navigation: any
  onDone: Function
  goHome: Function
}

export default function SmartNoAppointment ({ navigation, onDone, goHome }: SmartNoAppointmentProps) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Book Repair"/>
      <View style={styles.container}>
        <Para>
          We are Sorry.
        </Para>
        <Para>
          At this current time, we’re unable to find you a suitable appointment.
        </Para>
        <Para>
          If there is anything else we need, we will be in touch.
        </Para>
        <Para>
          We have received your repair request and a call back request has been sent to our repairer.
        </Para>
        <Para>
          You DO NOT need to contact us. A Shine! representative will call you within two working days.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onDone} text="Return to main screen"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
