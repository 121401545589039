import React, { Component } from 'react';
import { Keyboard, Pressable, StyleSheet, Text, View, Image } from 'react-native'
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';
import * as moment from 'moment'
import Moment from 'moment';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import waitingimg from '../../loading-png-gif.gif';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'




const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  listitemViews: {
	flexDirection: 'row'
  },
  listitems: {
	flex: 1,
	paddingLeft: 5,
	fontSize: 16
  },
  rowImage: {
	  height: 200,
    width: 200
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  }
})



class SmartRepairDate extends Component {
  constructor(props) {
    super();
    this.state = {
      date: new Date(),
	  suggested_dates: [],
	  dates_index: 0,
	  current_suggested_date: '',
	  loading: true,
	  arrival_time_text: '',
	  formatted_date: '',
	  showDatesText: 'Retrieving dates...',
    };
	this.GetShineDates(props);
  }
  
  
  GetShineDates(props) {
	  let theApp = this;
	  this.setState({suggested_dates:[]});
	props.api.shineDatesLookup(props.vehiclePostcode, function done(suggested_dates) {
      if (suggested_dates) {
		if (suggested_dates.length > 0)
		{
    setTimeout(function () {
        const nextState = Object.assign({}, theApp.state, {suggested_dates: suggested_dates});
        theApp.setState(nextState);
		console.log(suggested_dates);
		theApp.setState({current_suggested_date: suggested_dates[theApp.state.dates_index]});
		 var date = new Date(suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
		 var formattedDate = Moment(date).format('DD MMM YYYY');
		 console.log(formattedDate);
		 console.log(suggested_dates);
		theApp.setState({formatted_date: formattedDate});
		
		if (suggested_dates[theApp.state.dates_index].Timeslot == "AM")
		{
			theApp.setState({arrival_time_text: 'Between 8:00 am and 10:00am'});
		}
		else
		{
			theApp.setState({arrival_time_text: 'Between 11:30 and 15:30'});
		}
		
		theApp.setState({showDatesText: 'Show my dates'});
		console.log(suggested_dates);


    }, 50);
		}
		else
		{
			props.onNoAppointment();
		}

      }
	  else
		{
			props.onNoAppointment();
		}
    }, function notFound() {

    }, function fail(xhr) {
      console.log(xhr);
      //onFail();
    });	 
 }
 
   canGetDates() {
    return (
      this.state.suggested_dates.length > 0
    );
  }

   showDates() {
    this.setState({loading: false});
  }

GetNewShineDate()
 {
	 if (this.state.dates_index < (this.state.suggested_dates.length - 1))
	 {
		 const theApp = this;
		 var new_index = this.state.dates_index+1;
		 this.setState({dates_index: new_index});
		 //this.setState({loading: true});
		 setTimeout(function () {
			theApp.setState({current_suggested_date: theApp.state.suggested_dates[theApp.state.dates_index]});
			 var date = new Date(theApp.state.suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
			 var formattedDate = Moment(date).format('DD MMM YYYY');
			 console.log(formattedDate);
			theApp.setState({formatted_date: formattedDate});
			if (theApp.state.suggested_dates[theApp.state.dates_index].Timeslot == "AM")
			{
				theApp.setState({arrival_time_text: 'arrival time between 8:00 am and 10:00 am'});
			}
			else
			{
				theApp.setState({arrival_time_text: 'arrival time between 11:30 and 15:30'});
			}
			theApp.setState({loading: false});
			}, 5);
	
	 }
	 else
	 {
		 this.props.onNoAppointment();
	 }
 }

  render() {
		if (this.state.suggested_dates.length == 0 || this.state.loading == true)
		{
			return (
				<KeyboardAwareScrollView style={styles.outerContainer}>
				  <NavigationBar></NavigationBar>
				  <ScreenHeading text="Book Repair" goBack={this.props.goBack}/>
				  <View style={styles.container}>
					<Para>
						A typical repair is carried out within 30 days.
					</Para>
					<Para>
						You are now going to be offered up to five dates within the next 30 days.
					</Para>
					<Para>
						Please be aware once you have said no to an offered appointment this date will no longer be available as appointments are based on the availability of our repairer in your area and may have been reallocated.
					</Para>
					<Para>
						With a small number of claims a date won’t be offered but we will contact you directly so there is no need for you to call us.
					</Para>
					<View id='waiting-image'>
						<ConditionalButton text={this.state.showDatesText} onPress={() => this.showDates()} condition={() => this.canGetDates()} />
					</View>
				  </View>
				</KeyboardAwareScrollView>

			);
		}
		else
		{
			return (
				<KeyboardAwareScrollView style={styles.outerContainer}>
				  <NavigationBar goHome={this.props.goHome}></NavigationBar>
				  <ScreenHeading text="Book Repair" goBack={this.props.goBack}/>
				  <View style={styles.container}>
					<Para>
					  Thank you for your patience.
					</Para>
					<Para>
					  The next available date for your repair is:
					</Para>
					<Para>
					  {this.state.formatted_date + " " + this.state.current_suggested_date.Timeslot}
					</Para>
					<Para>
					  {this.state.arrival_time_text}
					</Para>
					<Para>
					  Do you wish to accept this date and time.
					</Para>
					<VerticalSpace height={15}/>
					<DefaultButton text="Yes" onPress={(e) => this.props.onRepairDateContinue(this.state.formatted_date + " " + this.state.current_suggested_date.Timeslot, this.state.current_suggested_date.Booking_Key)} />
					<VerticalSpace height={15}/>
					<DefaultButton text="No" onPress={(e) => this.GetNewShineDate()} />
					<VerticalSpace height={15}/>
				  </View>
				</KeyboardAwareScrollView>



			);
		}
  }

  setDate(newDate) {
    this.setState({date: newDate});
  }

  canSubmit() {
    return true;
  }
  

}

export default SmartRepairDate;


