import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface SmartThanksProps {
  navigation: any
  onDone: Function
  goHome: Function
  goBack: Function
}

export default function SmartThanks ({ navigation, onDone, goHome, goBack }: SmartThanksProps) {
  return (
    <SafeAreaView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Thank you for submitting your repair request/s, you will shortly receive an automated email confirming the information supplied.
        </Para>
        <Para>
          We'll review the information and images you sent us within the next two working days.
        </Para>
        <Para>
          If there's anything else we need, we'll be in touch.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onDone} text="Continue"/>
        <VerticalSpace height={15}/>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
