import React, { useState } from 'react'
import { Keyboard, Pressable, StyleSheet, Text, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import DefaultButton from '../../components/DefaultButton'
import ConditionalButton from '../../components/ConditionalButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import Moment from 'moment';

interface SmartDateProps {
  navigation: any
  onDateContinue: Function
  goHome: Function
  goBack: Function
  date: Any
}

export default function SmartDate ({ navigation, onDateContinue, goHome, goBack, date }: SmartDateProps) {
  const pickerDate = new Date(date)
  const [datePickerVisible, setDatePickerVisibile] = useState(false)
  const [selectedDate, setSelectedDate] = useState(date)
  const [datePickerText, setDatePickerText] = useState(Moment(pickerDate).format('DD-MM-YYYY') || '')

  const showDatePicker = () => setDatePickerVisibile(true)
  const hideDatePicker = () => setDatePickerVisibile(false)

  const handleConfirm = (newDate: Date): void => {
    const formattedDate = String(Moment(newDate).format('DD-MM-YYYY'))
	setSelectedDate(newDate)
    hideDatePicker()
    setDatePickerText(formattedDate)
  }

  const handleCancel = () => {
    Keyboard.dismiss()
    hideDatePicker()
  }
  
  function canSubmit () {
    return pickerDate <= new Date();
  }
  
  function doContinue()
  {
	  onDateContinue(selectedDate);
  }


  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
      <ScreenHeading text="Repair Request" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Please confirm that all the details you have provided within this repair request are in-line with the terms and conditions.
        </Para>
        <Para>
          Note, providing inaccurate information may result in a delay in assessing your repair request.
        </Para>
        <Para>
          By continuing you promise the information provided is true and correct.
        </Para>
        <Para>
          When did you notice the damage? Select a date.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={showDatePicker} text="Click to select a date"/>
          <Para>Selected date: {datePickerText}</Para>
        <DateTimePickerModal
          date={pickerDate}
          isVisible={datePickerVisible}
          mode="date"
          maximumDate={new Date()}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          locale="en_GB"
        />
        <VerticalSpace height={15}/>
        <ConditionalButton text="Continue" condition={canSubmit} onPress={doContinue} />
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}



const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
