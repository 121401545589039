import React from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'

type NavigationBarProps = {
  navigation: any
  goHome: Function
}

const NavigationBar = ({ navigation, goHome }: NavigationBarProps) => {
  return (
    <View style={styles.navigationBar}>
      <NavigationBarIcons navigation={navigation} goHome={goHome}></NavigationBarIcons>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    // backgroundColor: Colors.primaryPurple, // AP
    backgroundColor: Colors.white, // Shine
    // color: Colors.white, // AP
    color: Colors.dark, // Shine
    paddingBottom: 15,
    paddingTop: 40
  }
})

export default NavigationBar
