import React from 'react'
import { StyleSheet, View, TextInput } from 'react-native'
//import { TextInput } from 'react-native-gesture-handler'
import Para from './Para'

type LabeledTextFieldProps = {
  label: string,
  onChange: (text: string) => void,
  placeholder?: string
  value: string
}

const LabeledTextField = ({ label, onChange, placeholder = '', value }: LabeledTextFieldProps) => {
  return (
    <View>
      <Para>{label}</Para>
      <TextInput value={value} placeholder={placeholder} style={styles.textInput} onChangeText={onChange}/>
    </View>
  )
}

export default LabeledTextField

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 35,
    paddingHorizontal: 4
  }
})
