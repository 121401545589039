import React, { Component } from 'react';
import { StyleSheet, View, Linking } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

class TrustPilot extends Component {
  constructor() {
    super();
  }

  render() {
    return (
	<KeyboardAwareScrollView style={styles.outerContainer}>
	  <NavigationBar navigation={this.props.navigation} goHome={this.props.goHome}></NavigationBar>
        <ScreenHeading text={"Repair Request"} goBack={this.props.goBack}/>
		<View style={styles.container}>
			<Para>
			  Thank you for using our app
			</Para>
			<Para>
			  We would be grateful if you would leave feedback in order that we can continuously improve our processes based on your input.
			</Para>
			<VerticalSpace height={15}/>
				  <DefaultButton text="Click to review" onPress={() => Linking.openURL(`https://uk.trustpilot.com/evaluate/www.shinerepair.com`)}/>
			<VerticalSpace height={15}/>
      </View>
	</KeyboardAwareScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})


export default TrustPilot;
