import React from 'react'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para';
import SmartFail from './SmartFail';
import Heading from '../../components/Heading';
import ScreenHeading from '../../components/ScreenHeading';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Styles from '../../constants/Styles'
import * as Progress from 'react-native-progress';

interface SmartSubmittingProps {
  navigation: any
  goHome: Function
  onRetry: Function
  goBack: Function
  photo1UploadProgress: any
  photo2UploadProgress: any
  photoUploadFailed: any
}

export default function SmartSubmitting ({ navigation, goHome, goBack, onRetry, photo1UploadProgress, photo2UploadProgress, photoUploadFailed }: SmartSubmittingProps) {
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
        <NavigationBar navigation={navigation} goHome={goHome}></NavigationBar>
		  <ScreenHeading text={"Smart Claim"} />
			<View style={styles.container}>
			  <Heading>Submitting...</Heading>
			  <Para>
				Please wait while your claim is being submitted.
			  </Para>
				<Para bold={true}>Upload progress:</Para>
				<Para>Photo 1: ({Math.floor(photo1UploadProgress*100).toString()}%)</Para>
				<Progress.Bar progress={photo1UploadProgress} width={200} />
				<Para>Photo 2: ({Math.floor(photo2UploadProgress*100).toString()}%)</Para>
				<Progress.Bar progress={photo2UploadProgress} width={200} />
					{photoUploadFailed == false ? null :
						<SmartFail onRetry={onRetry} />
					}
			</View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
